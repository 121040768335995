@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  min-height: 100%;
}

body {
  @apply bg-custom-bg bg-custom-gradient font-mono text-sm text-white;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
